import { SEO } from "#root/components/SEO";
import { RouteSectionProps } from "@solidjs/router";
import { clientOnly } from "@solidjs/start";

const ClientOnlyAppLayout = clientOnly(() => import("../layouts/AppLayout"));

export default function AppLayout(props: RouteSectionProps) {
  return (
    <>
      <SEO noIndex />
      <ClientOnlyAppLayout>
        <div>{props.children}</div>
      </ClientOnlyAppLayout>
    </>
  );
}
